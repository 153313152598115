import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {history} from '../routers/AppRouter'
// import Sidebar from '../components/Sidebar'

const PrivateRoute = (Component, allowedUserTypes) => {
    return class extends React.Component {
        constructor() {
            super()
            this.state = {
                loading: true,
                redirect: false
            }
        }

        componentDidMount() {
            fetch(`${process.env.REACT_APP_SERVER_URL}/checkToken`, {
                credentials: 'include'
            }).then(res => {
                if (res.ok) this.setState({ loading: false })
                else history.push('/')
            }).catch(err => {
                console.log(err)
                this.setState({ loading: false, redirect: true })
            })
        }

        render() {
            const {loading, redirect} = this.state
            if (loading) return <div style={{backgroundColor: "#eee", height: "100vh"}}></div>
            if (redirect) return <Redirect to="/" />
            return <Component {...this.props} />
        }
    }
}

export default PrivateRoute