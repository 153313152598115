import React from 'react'
import {history} from '../routers/AppRouter'

class CVPage extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.state = {
            key: "",
            menuToggle: false,
            page: "intro",
            data: {
                about: {},
                contact: {},
                education: {},
                experience: {},
                interests: {},
                intro: {},
                pnc: {},
                skills: {}
            }
        }

        this.pages = ["intro", "skills", "edu", "exp", "pc", "interests", "contact", "about"]
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_SERVER_URL}/getcv`, {
            credentials: 'include'
        }).then(res => {
            return res.json()
        }).then(data => {
            this.setState({data})
            console.log(data)
        }).catch(err => console.log(err))
    }

    onChangeKey = e => {
        e.preventDefault()
        this.setState({key: e.target.value})
    }

    signOut = e => {
        e.preventDefault()
        history.push('/')
    }

    goto = e => {
        e.preventDefault()
        this.setState({page: e.target.id})
    }

    next = e => {
        e.preventDefault()
        this.setState({page: this.pages[this.pages.indexOf(this.state.page)+1]})
    }

    previous = e => {
        e.preventDefault()
        this.setState({page: this.pages[this.pages.indexOf(this.state.page)-1]})
    }

    render() {
        let {menuToggle, page} = this.state
        let {about, contact, education, experience, interests, intro, pnc, skills} = this.state.data
        return (
            <div className="cvpage">
                <div className="nav">
                    <span id="intro" className={page === "intro" ? "bold" : ""} onClick={this.goto}>Introduction</span>
                    <i className={`fas fa-angle-right${page === "intro" ? " orange" : ""}`} />
                    <span id="skills" className={page === "skills" ? "bold" : ""} onClick={this.goto}>Skills</span>
                    <i className={`fas fa-angle-right${page === "skills" ? " orange" : ""}`} />
                    <span id="edu" className={page === "edu" ? "bold" : ""} onClick={this.goto}>Education</span>
                    <i className={`fas fa-angle-right${page === "edu" ? " orange" : ""}`} />
                    <span id="exp" className={page === "exp" ? "bold" : ""} onClick={this.goto}>Work Experience</span>
                    <i className={`fas fa-angle-right${page === "exp" ? " orange" : ""}`} />
                    <span id="pc" className={page === "pc" ? "bold" : ""} onClick={this.goto}>Pros & Cons</span>
                    <i className={`fas fa-angle-right${page === "pc" ? " orange" : ""}`} />
                    <span id="interests" className={page === "interests" ? "bold" : ""} onClick={this.goto}>Interests</span>
                    <i className={`fas fa-angle-right${page === "interests" ? " orange" : ""}`} />
                    <span id="contact" className={page === "contact" ? "bold" : ""} onClick={this.goto}>Contact</span>
                    <i className={`fas fa-angle-right${page === "contact" ? " orange" : ""}`} />
                    <span id="about" className={page === "about" ? "bold" : ""} onClick={this.goto}>About</span>
                </div>
                <div className="content">
                    <div className={`${page === "intro" ? "" : "hidden "}info introduction`}>
                        <h1>{intro.title}</h1>
                        <p>{intro.content}</p>
                    </div>
                    <div className={`${page === "skills" ? "" : "hidden "}info skills`}>
                        <h1 className="h1-mobile">{skills.title}</h1>
                        <div className="programming">
                            <h2>{skills.prog?.title}</h2>
                            {skills.prog?.content.map(item => {
                                return <p>{item}</p>
                            })}
                        </div>
                        <div className="left">
                            <h2>{skills.languages?.title}</h2>
                            {skills.languages?.content.map(item => {
                                return <>
                                    <p className="language">{item.language}</p>
                                    {item.info.map(item => {
                                        return <p>{item}</p>
                                    })}
                                </>
                            })}
                        </div>
                        <div className="right">
                            <h2>{skills.other?.title}</h2>
                            {skills.other?.content.map(item => {
                                return <p>{item}</p>
                            })}
                        </div>
                    </div>
                    <div className={`${page === "edu" ? "" : "hidden "}info education`}>
                        <h1 className="h1-mobile">{education.title}</h1>
                        {education?.content?.map(item => {
                            return <div>
                                <p className="years">{item.years}</p>
                                {item.info.map(item => {
                                    return <p>{item}</p>
                                })}
                            </div>
                        })}
                    </div>
                    <div className={`${page === "exp" ? "" : "hidden "}info exp`}>
                        <h1 className="h1-mobile">{experience.title}</h1>
                        {experience?.content?.map(item => {
                            return <div>
                                <p className="company">{item.company}</p>
                                <p className="date">{item.dates}</p>
                                <p className="desc">{item.info}</p>
                            </div>
                        })}
                    </div>
                    <div className={`${page === "pc" ? "" : "hidden "}info pnc`}>
                        <h1 className="h1-mobile">{pnc.title}</h1>
                        <div className="pros">
                            <h2>Unique Pros</h2>
                            {pnc?.pros?.map(item => {
                                return <p>{item}</p>
                            })}
                        </div>
                        <div className="cons">
                            <h2>What I'm Working On Improving</h2>
                            {pnc?.cons?.map(item => {
                                return <p>{item}</p>
                            })}
                        </div>
                    </div>
                    <div className={`${page === "interests" ? "" : "hidden "}info interests`}>
                        <h1 className="h1-mobile">{interests.title}</h1>
                        <div>
                            {interests?.content?.map(item => {
                                return <>
                                    <h2>{item.title}</h2>
                                    <p>{item.info}</p>
                                </>
                            })}
                        </div>
                    </div>
                    <div className={`${page === "contact" ? "" : "hidden "}info contact`}>
                        <h1 className="h1-mobile">{contact.title}</h1>
                        <div>
                            {contact?.content?.map(item => {
                                return <>
                                    <h2>{item.title}</h2>
                                    <p>{item.info}</p>
                                </>
                            })}
                        </div>
                    </div>
                    <div className={`${page === "about" ? "" : "hidden "}info about`}>
                        <h1>{about.title}</h1>
                        <p>{about.content}</p>
                    </div>
                    {page !== "intro" && <i className="fas fa-angle-left" onClick={this.previous} />}
                    {page !== "about" && <i className="fas fa-angle-right" onClick={this.next} />}
                </div>
            </div>
        )
    }
}

export default CVPage