import React from 'react'
import {history} from '../routers/AppRouter'

class MainPage extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.state = {
            key: "",
            keyError: false
        }
    }

    onChangeKey = e => {
        e.preventDefault()
        this.setState({key: e.target.value})
    }

    continue = e => {
        e.preventDefault()
        if (!this.state.key) return

        fetch(`${process.env.REACT_APP_SERVER_URL}/keycheck`, {
            method: 'POST',
            body: JSON.stringify({key: this.state.key}),
            credentials: 'include',
            headers: {"Content-Type": "application/json"}
        }).then(res => {
            if (res.status === 200) {
                history.push('/cv')
            } else {
                // return res.json()
                this.setState({keyError: true})
                setTimeout(() => {
                    this.setState({keyError: false})
                }, 1000);
            }
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        let key = this.state.key
        return (
            <div className="mainpage">
                <div className="mainpage-content">
                    <h1>Hi, welcome to my CV</h1>
                    <h2>Use your key to access my information</h2>
                    <input className={`${this.state.keyError ? "error" : ""}`} type="text" placeholder="Your key here" value={key} onChange={this.onChangeKey} />
                    <a className={`${key ? "active" : ""}`} onClick={this.continue}><i className="fas fa-key"/> <p>Continue</p></a>
                </div>
            </div>
        )
    }
}

export default MainPage