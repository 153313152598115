import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const PublicRoute = (Component) => {
    return class extends React.Component {
        constructor() {
            super()
            this.state = {
                loading: true,
                redirect: false
            }
        }

        componentDidMount() {
            fetch(`${process.env.REACT_APP_SERVER_URL}/checkToken`, {
                credentials: 'include'
            }).then(res => {
                if (res.ok) {
                    this.setState({ loading: false, redirect: true })
                } else if (res.status === 401) {
                    console.log('not logged in')
                    this.setState({ loading: false, redirect: false })
                } else {
                    const error = new Error(res.error)
                    throw error
                }
            }).catch(err => {
                console.log(err)
                this.setState({loading: false, redirect: false})
            })
        }
        
        render() {
            const {loading, redirect} = this.state
            if (loading) return <Component/>
            if (redirect) return <Redirect to="/cv" />
            else return <Component {...this.props} />
        }
    }
}

export default PublicRoute