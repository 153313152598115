import {Router, Route, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import React from 'react'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
// import Header from '../components/Header'
import MainPage from '../components/MainPage'
import CVPage from '../components/CVPage'

export const history = createBrowserHistory();

class AppRouter extends React.Component {
    render() {
        return (
            <Router history={history}>
                <div>
                    <Switch>
                        <Route path="/" component={PublicRoute(MainPage)} exact={true} />
                        <Route path="/cv" component={PrivateRoute(CVPage)} exact={true} />
                    </Switch>
                </div>
            </Router>
        )
    }
}
export default AppRouter;